// import(
//   "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0/dist/cookieconsent.umd.js"
// );
CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: "cloud inline",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "cloud",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
    },
    functionality: {},
    analytics: {},
  },
  language: {
    default: "es",
    autoDetect: "browser",
    translations: {
      es: {
        consentModal: {
          title: "",
          description:
            "Este sitio web utiliza cookies y otras tecnologías, propias y de terceros, para obtener información sobre tus preferencias, navegación y comportamiento en este sitio web. Esto nos permite proporcionarte distintas funcionalidades en la página web, personalizar la forma en la que se te muestra, o analizar nuestro tráfico. Puedes consultar más información sobre nuestra Política de cookies <a href='/politica-de-cookies.html'>AQUI</a>",
          acceptAllBtn: "Aceptar todo",
          acceptNecessaryBtn: "Rechazar todo",
          showPreferencesBtn: "Gestionar preferencias",
          footer:
            '<a href="/politica-privacidad.html">Política de privacidad</a>\n<a href="/aviso-legal.html">Términos y condiciones</a>',
        },
        preferencesModal: {
          title: "Preferencias de Consentimiento",
          acceptAllBtn: "Aceptar todo",
          acceptNecessaryBtn: "Rechazar todo",
          savePreferencesBtn: "Guardar preferencias",
          closeIconLabel: "Cerrar modal",
          serviceCounterLabel: "Servicios",
          sections: [
            {
              title: "Uso de Cookies",
              description:
                "Este sitio web utiliza cookies y tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación o personalizar la forma en que se muestra el contenido.",
            },
            {
              title:
                'Cookies Estrictamente Necesarias <span class="pm__badge">Siempre Habilitado</span>',
              description:
                "Las cookies estrictamente necesarias son aquellas de carácter técnico, que deben estar siempre activadas para que la web funcione correctamente, así como para que podamos guardar tus preferencias de ajustes de cookies.",
              linkedCategory: "necessary",
            },
            {
              title: "Cookies Analíticas",
              description:
                "Esta web utiliza Google Analitycs para recopilar información anónima que nos permita medir, por ejemplo, el número de visitantes del sitio, o las páginas más populares.<br>Activando estas cookies, nos ayudarás a continuar mejorando nuestra web en base los intereses de nuestros usuarios.",
              linkedCategory: "analytics",
            },
          ],
        },
      },
    },
  },
  disablePageInteraction: true,
});
